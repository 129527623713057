<template>
  <a-card class="card" :bordered="false">
    <base-page
      @modelShow="handleModelShow"
      :queryFields="queryFields"
      :defaultQueryParam="defaultQueryParams"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getItmsMetaPage"
      :deleteFun="deleteItmsMetaInfo"
      :createFun="createItmsMetaInfo"
      :updateFun="updateItmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getItmsMetaPage, createItmsMetaInfo, updateItmsMetaInfo, deleteItmsMetaInfo } from '@/api/itms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '报关行', name: 'name', fieldType: '', queryType: '%'
        }
      ],
      defaultQueryParams: {
        role_type: 3
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '报关行',
          dataIndex: 'name'
        },
        {
          title: '简码',
          dataIndex: 'short_code'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入报关行', trigger: 'blur' }
        ]
      },
      modelTitle: '报关行',
      formFields: [
        {
          label: '报关行名称', name: 'name', type: 'default', ref: 'name'
        },
        {
          label: '简码', name: 'short_code', type: 'default'
        },
        {
          label: '', name: 'role_type', type: 'hidden', defaultValue: 3
        }
      ],
      moduleName: 'itms_role'
    }
  },
  methods: {
    getItmsMetaPage,
    createItmsMetaInfo,
    updateItmsMetaInfo,
    deleteItmsMetaInfo,
    handleModelShow(a) {
      const nameObj = a.$refs['name'][0].$refs.input
      nameObj.oninput = () => {
        a.originForm.short_code = getFirstLetter(a.originForm.name)
      }
    }
  }
}
</script>
